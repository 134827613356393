import SockJS from "sockjs-client";
import Stomp from "stompjs";
import { DEV_MODE, ENCRYPT_PHARAGRAPH } from "../config/const";
import { IClientInfo } from "../store/models/FingerPrintModel";
import { getUrlWebSocketByRole } from "../utils/general/utils";
import { useEffect, useState } from "react";

const useClientWebSocket = () => {
  // dependencies
  var aesEcb = require("aes-ecb");
  // Constants
  const needToEncryptData = sessionStorage.getItem("aes-encrypt");
  // local states
  const [showModalAlertDevice, setShowModalAlertDevice] =
    useState<boolean>(false);
  const [responseSocketTemp, setResponseSocketTemp] = useState<IClientInfo>({
    dateTime: "",
    httpStatus: 0,
    ip: "",
    mac: "",
    deviceSerial: "",
    message: "",
    responseCode: null,
  });
  var stompClient: any = null;
  var socketWS: any = null;
  var urlSocket = getUrlWebSocketByRole();

  /**
   * Decrypt data
   * @function
   * @param {any} data data to submit
   */
  const decryptDataReceibed = (data: any) => {
    const dataDecrypted = aesEcb.decrypt(ENCRYPT_PHARAGRAPH, data);

    return dataDecrypted;
  };
  // Parsing and Encrypt data to send
  function parseObjectData(obj: any) {
    try {
      const objParsed = JSON.parse(obj);
      return objParsed;
    } catch (error) {}
  }

  // open new conection to desktop web socketWS and try to connect in local if fail at first time
  const openSocketConnection = (
    urlSocket: string,
    _customFunction?: any,
    urlLocal?: any
  ) => {
    let socket = new SockJS(`${urlSocket}clientInfo`);

    stompClient = Stomp.over(socket);

    stompClient.connect(
      {},
      (frame: any) => {
        sendRequestClientInfo();

        stompClient.subscribe("/topic/clientInfo", (message: any) => {
          const parsedData = parseObjectData(message.body);

          !urlLocal
            ? callbackSuscribe(parsedData, _customFunction, true)
            : callbackSuscribe(parsedData, _customFunction);
        });
      },
      (error: any) => {
        if (needToEncryptData === "0" && !!urlLocal) {
          openSocketConnection(urlLocal, _customFunction, null);
        } else if (needToEncryptData === "0" && !urlLocal) {
          console.error("Error : ", error);
          setShowModalAlertDevice(true);
        }
      }
    );
  };

  // Open and handle socket conection for diferent roles
  const ConnectClientInfo = (_customFunction?: any) => {
    if (urlSocket?.role === "movil") {
      socketWS = new WebSocket(`${urlSocket.url}clientInfo`);

      socketWS.onopen = () => {
        socketWS.send("");
      };

      socketWS.onerror = () => {
        setShowModalAlertDevice(true);
      };

      socketWS.onmessage = (_response: { data: any }) => {
        const parsedObj = parseObjectData(_response.data);

        callbackSuscribe(parsedObj, _customFunction);
      };
    } else {
      urlSocket &&
        openSocketConnection(
          urlSocket?.url,
          _customFunction,
          urlSocket?.urlLocal
        );
    }
  };

  function DisconnectClientInfo() {
    if (stompClient != null) {
      stompClient.disconnect();
    } else if (socketWS !== null) {
      socketWS.close();
    }
  }

  const callbackSuscribe = (
    _data: any,
    _customFunction?: any,
    isEncrypted: boolean = false
  ) => {
    let objToSend;

    if (!!isEncrypted) {
      sessionStorage.setItem("aes-encrypt", "1");
    } else {
      sessionStorage.setItem("aes-encrypt", "0");
    }

    if (_data) {
      objToSend = {
        dateTime: _data.dateTime,
        httpStatus: _data.httpStatus,
        ip: isEncrypted ? decryptDataReceibed(_data.ip) : _data.ip,
        mac: isEncrypted ? decryptDataReceibed(_data.mac) : _data.mac,
        deviceSerial: isEncrypted
          ? decryptDataReceibed(_data.deviceSerial)
          : _data.deviceSerial,
        message: _data.message,
        responseCode: _data.responseCode,
      };

      setResponseSocketTemp(objToSend);
      DisconnectClientInfo();

      if (!_data.responseCode && _customFunction) {
        _customFunction(objToSend);
      }
    }
  };

  const sendRequestClientInfo = () => {
    if (!!stompClient) {
      stompClient.send(
        "/app/clientInfo",
        {},
        JSON.stringify({
          dev: DEV_MODE,
        })
      );
    }
  };

  useEffect(() => {
    sessionStorage.setItem("aes-encrypt", "0");
    ConnectClientInfo();
  }, []);

  return {
    showModalAlertDevice,
    setShowModalAlertDevice,
    responseSocketTemp,
    ConnectClientInfo,
    DisconnectClientInfo,
  };
};

export default useClientWebSocket;
